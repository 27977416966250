.gray {
  padding: 42px 24px 50px;
  color: rgba(0, 0, 0, 0.88);
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  background-color: rgb(240, 242, 245);
  height: 100vh;
}


.content {
  padding: 50px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.load {
  width: 100%;
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: rgba(255,255,255,0.8);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

th {
  overflow: hidden;
white-space: nowrap;
}

.tableParent {
  overflow: auto;
}